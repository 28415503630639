

.App {
  transform: scale(0.95);
  transform-origin: 0 0;
  width: 105.22%;
  
}
.App{
  background: "gray";
}

